<template>
<div>
        <div>
            <nav class="sb-topnav navbar navbar-expand navbar-dark" style="background:#FF6961">
                <router-link class="navbar-brand" to="/"><img src="/img/bcart-white.png" height="30px" alt=""> v1.0</router-link><button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="#"><i class="fas fa-bars"></i></button>
                <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                <!--  <div class="input-group">
                        <input class="form-control" type="text" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button"><i class="fas fa-search"></i></button>
                        </div>
                    </div> -->
                </form>
            
                <!-- Navbar-->
                <ul class="navbar-nav ml-auto ml-md-0">
                    <li class="nav-item dropdown no-arrow">
                        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline text-gray-600 small text-white"> {{authUser.user.username}}</span>
                            <img class="img-profile rounded-circle" height="40px" src="/img/services.png">
                        </a>
                    <!-- Dropdown - User Information -->
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <router-link to="panel/profile" class="dropdown-item">
                                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Profile
                            </router-link>
                           <!--  <a class="dropdown-item" href="#">
                                <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </a> -->
                            <a class="dropdown-item" href="#">
                                <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" @click.prevent="logout" data-toggle="modal" data-target="#logoutModal">
                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                   
                </ul>
            </nav>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <div class="sb-sidenav-menu">
                            <div class="nav">
                                <div class="sb-sidenav-menu-heading"><!-- Core --></div>
                                <a class="nav-link" @click.prevent="route('/panel/dashboard')">
                                    <div class="sb-nav-link-icon">
                                        <i class="fas fa-tachometer-alt"></i>
                                    </div>
                                    Dashboard
                                </a>
                                <a class="nav-link" @click.prevent="route('/panel/categories')"
                                ><div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                Categories</a>
                                 <a class="nav-link" @click.prevent="route('/panel/creative-categories')"
                                ><div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                Creative Categories</a>
                                

                                
                                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts"
                                    ><div class="sb-nav-link-icon"><i class="fas fa-camera"></i></div>
                                    Stock Photos
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div
                                ></a>
                                <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav">
                                        <a @click.prevent="route('/panel/photos')" class="nav-link">All Photos</a>
                                        <a @click.prevent="route('/panel/photos/active')" class="nav-link">Active Photos</a>
                                        <a @click.prevent="route('/panel/photos/inactive')" class="nav-link">Inactive Photos</a>
                                    </nav>
                                </div>
                                <!-- <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#support" aria-expanded="false" aria-controls="support"
                                    ><div class="sb-nav-link-icon"><i class="fas fa-sticky-note"></i></div>
                                    Tickets
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div
                                ></a>
                                <div class="collapse" id="support" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav">
                                        <a @click.prevent="route('/panel/products')" class="nav-link">Answered Tickets</a>
                                        <a @click.prevent="route('/panel/products/add')" class="nav-link">Unanswered Tickets</a>
                                    </nav>
                                </div> -->
                               
                                <!-- <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#creatives" aria-expanded="false" aria-controls="support"
                                    ><div class="sb-nav-link-icon"><i class="fas fa-users"></i></div>
                                    Creatives
                                    <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div
                                ></a>
                                <div class="collapse" id="creatives" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
                                    <nav class="sb-sidenav-menu-nested nav">
                                        <a @click.prevent="route('/panel/creatives')" class="nav-link">Pending Requests</a>
                                        <a @click.prevent="route('/panel/photos/add')" class="nav-link">All Creatives</a>
                                    </nav>
                                </div> -->
                                 <a class="nav-link" @click.prevent="route('/panel/weeklybanner')"
                                ><div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                Weekly Banner</a>
                                <a class="nav-link" @click.prevent="route('/panel/creatives')"
                                ><div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                All Creatives</a>
                                <a class="nav-link" @click.prevent="route('/panel/users')"
                                ><div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                Users</a>
                                
                                
                                <a class="nav-link" href="charts.html"
                                    ><div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                                    Logout</a>
                            </div>
                        </div>
                        <div class="sb-sidenav-footer">
                            <div class="small">Logged in as:  {{authUser.user.username}}</div>
                           
                        </div>
                    </nav>
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        <transition appear name="custom-classes-transition" enter-active-class="animated fadeIn">
                            <div class="container-fluid pl-md-100">
                                <div class="" >
                                    <router-view></router-view>
                                </div>
                            </div>
                        </transition>
                    </main>
                    <!-- <footer class="py-4 bg-light mt-auto">
                        <div class="container-fluid">
                            <div class="d-flex align-items-center justify-content-between small">
                                <div class="text-muted">Copyright &copy; Aljani 2020</div>
                                <div>
                                    <a href="#">Privacy Policy</a>
                                    &middot;
                                    <a href="#">Terms &amp; Conditions</a>
                                </div>
                            </div>
                        </div>
                    </footer> -->
                </div>
           
            </div>
           
        </div>
</div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        data(){
            return{
                user:{
                    name:'',
                    email:''
                },
            }
        },
        computed: {
            ...mapState({
                authUser : state => state.authStore.authUser,
                routeStore: state => state.routeStore
            }),
        },
        mounted() {
            $("#sidebarToggle").on("click", function(e) {
                e.preventDefault();
                $("body").toggleClass("sb-sidenav-toggled");
            });
        },
        methods:{
            logout(){
                this.$store.dispatch('unsetAuthUser')
                this.$router.push({name:'Login'})
            },
            route(path) {
                this.$router.push(path)
                if(window.matchMedia("(max-width: 767px)").matches){
                    // The viewport is less than 768 pixels wide
                    $("body").toggleClass("sb-sidenav-toggled");
                }
            }
        },
    }
</script>


<style scoped src="@/assets/css/panel.css">
</style>
